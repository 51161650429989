import { message, Modal } from 'antd';
import type { BaseData } from 'egenie-common';
import { EgGridModel, request } from 'egenie-utils';
import _ from 'lodash';
import { action, observable, reaction, runInAction } from 'mobx';
import { nanoid } from 'nanoid';
import { api } from '../../../utils';
import { DRAWER_COLUMNS, SHELVE_URL, statusDict } from '../constant';
import type { IPutAway, IUpdateState } from '../interface';
import ProductAnalysisStore from '../productDataModal/store';
import type ParentStore from '../store';
import StrategyStore from '../strategy/store';

export default class Store {
  constructor(parent: ParentStore) {
    this.parent = parent;
  }

  @observable public parent: ParentStore;

  @observable public refreshFlag = false;

  @observable public strategyStore = new StrategyStore(this);

  @observable public productAnalysisStore = new ProductAnalysisStore(this);

  @observable public styleId;

  @observable public showDrawer = false;

  @observable public styleNo = '';// 款式编码

  @observable public posGoodsId = 0;// posId 复制平台商品的时候用

  @observable public styleName = '';// 款式名称

  @observable public showWarehouseModal = false;// 是否展示仓库中上架弹窗

  @observable public shopId = 0;

  @observable public platformId = 0;

  @observable public goodsId = 0;

  @observable public shelveDirectlyLoading = false;

  @action public toggleWarehouseModal = (flag: boolean, shopId?: number, platformId?: number, goodsId?: number) => {
    this.showWarehouseModal = flag;
    this.shopId = shopId;
    this.platformId = platformId;
    this.goodsId = goodsId;
  };

  // 上架按钮
  public handleOnShelve = (shopId: number, platformId: number, goodsId: number, salesStatus: number) => {
    if (salesStatus === 2) {
      this.toggleWarehouseModal(true, shopId, platformId, goodsId);
    } else {
      this.shelveProduct(shopId, platformId, goodsId);
    }
  };

  // 直接上架
  public shelveDirectly = (shopId, platformId, goodsId) => {
    this.shelveDirectlyLoading = true;
    return request<BaseData<IUpdateState>>({
      url: '/api/gms/goods/updateSaleStatus',
      method: 'POST',
      data: { gmsGoodsId: goodsId },
    }).then((res) => {
      const { success } = res.data;

      if (success) {
        message.success('上架成功');
        this.styleId && this.getProductList(this.styleId);
        this.parent.programme.handleSearch();
      } else {
        const { message } = res.data;
        Modal.destroyAll();
        Modal.confirm({
          title: '提示',
          content: `${message}不符合上架条件,请重新编辑后上架`,
          onOk: () => {
            return this.shelveProduct(shopId, platformId, goodsId);
          },
        });
      }
      this.toggleWarehouseModal(false);
    })
      .finally(() => {
        this.shelveDirectlyLoading = false;
      });
  };

  // 上架商品
  public shelveProduct = async(shopId: number, platformId: number, goodsId: number) => {
    // 检查授权
    const checkAuthRes: BaseData = await request({
      url: api.validePermission,
      method: 'POST',
      data: { shopId },
    });
    if (checkAuthRes.data) {
      const pageId = nanoid(5);
      window.top.egenie.openTab(SHELVE_URL[platformId](goodsId, shopId, pageId), pageId, '上架');
    } else {
      // 未授权
      Modal.confirm({
        title: '提示',
        content: '该店铺还未授权，请进行授权',
        okText: '去授权',
        onOk: () => {
          return request<BaseData<any>>({ url: `${api.gotoAuth}/${shopId}` }).then((res) => {
            window.top.open(res.data);
          });
        },
      });
    }
    this.toggleWarehouseModal(false);
  };

  @observable public egGridModel = new EgGridModel({
    columns: DRAWER_COLUMNS(this).map((item) => ({
      ...item,
      resizable: true,
    })),
    rows: [],
    primaryKeyField: 'gmsGoodsId',
    rowHeight: 84,
    showCheckBox: false,
    showPager: false,
  });

  @action public openDrawer = (selectedRow) => {
    const { gmsStyleId, styleNo, styleName, posGoodsId } = selectedRow;
    this.showDrawer = true;
    this.styleNo = styleNo;
    this.styleName = styleName;
    this.posGoodsId = posGoodsId;
    this.styleId = gmsStyleId;
    this.getProductList(gmsStyleId);
  };

  public getProductList = (gmsStyleId: number) => {
    this.egGridModel.loading = true;
    request<BaseData<any[]>>({
      url: '/api/gms/goods/queryGoodsListByStyleId',
      method: 'POST',
      data: { gmsStyleId },
    }).then((res) => {
      runInAction(() => {
        // 根据顶部状态做铺货店铺商品的过滤
        const selectedStatus = statusDict[this.parent.activeStatus].value;

        let filteredGoodsList;

        // 全部的情况无需过滤
        if (!selectedStatus) {
          filteredGoodsList = res.data;
        } else {
          filteredGoodsList = res.data?.filter((item) => {
            return selectedStatus.some((selectedStatusValue) => item.salesStatus === selectedStatusValue);
          });
        }

        this.egGridModel.rows = _.sortBy(filteredGoodsList, 'putAwayTime').reverse();
      });
    })
      .finally(() => {
        runInAction(() => {
          this.egGridModel.loading = false;
        });
      });
  };

  @action public closeDrawer = () => {
    this.showDrawer = false;
    this.egGridModel.rows = [];
  };

  @action public handleProductPutaway = (gmsGoodsId) => {
    Modal.confirm({
      title: '确定将商品下架？',
      onOk: () => {
        return request<BaseData<IPutAway>>({
          url: api.delisting,
          method: 'post',
          data: { gmsGoodsId: [gmsGoodsId]},
        }).then((res) => {
          message.success(res.data.operationName);
        });
      },
    });
  };

  // 策略
  @action public openStrategy = (row, flag): void => {
    const productList = [];
    const { goodsName, gmsGoodsId, smallPicUrl } = row;
    productList.push({
      goodsName,
      gmsGoodsId,
      smallPicUrl,
    });
    this.strategyStore.onShow(productList, flag);
  };

  // 处理复制平台商品弹窗关闭
  @action public handleCopyModalClose = (refreshFlag) => {
    this.showDrawer = true;
    if (refreshFlag) {
      this.getProductList(this.styleId);
    }
  };
}
