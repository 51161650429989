import moment from 'moment';
import type { ReactNode } from 'react';
import React, { Component } from 'react';
import styles from './index.less';
import type store from './store';

export default class StrategicTask extends Component<{ store?: store; }> {
  render(): ReactNode {
    const { deletedTask, unfinishedTask, completedTask } = this.props.store;
    return (
      <div className={styles.StrategicTaskContainer}>
        <div className={styles.unfinishedTask}>
          <div className={`${styles.unfinishedTitle} ${styles.heightFormat}`}>
            未完成
          </div>
          <div>
            {unfinishedTask.map((item) => {
              return (
                <div
                  className={styles.taskItem}
                  key={item.gmsGoodsStrategyId}
                >
                  <div className={styles.taskName}>
                    {item.name}
                  </div>
                  <div>
                    {moment(item.createTime).format('YYYY.MM.DD')}
                    分配
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className={styles.completedTask}>
          <div className={`${styles.completedTitle} ${styles.heightFormat}`}>
            已完成
          </div>
          <div>
            {completedTask.map((item) => {
              return (
                <div
                  className={styles.taskItem}
                  key={item.gmsGoodsStrategyId}
                >
                  <div className={styles.taskName}>
                    {item.name}
                  </div>
                  <div className={styles.mr10}>
                    {moment(item.createTime).format('YYYY.MM.DD')}
                    分配
                  </div>
                  <div>
                    {moment(item.lastUpdateTime).format('YYYY.MM.DD')}
                    完成
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className={styles.deletedTask}>
          <div className={`${styles.deletedTitle} ${styles.heightFormat}`}>
            已删除
          </div>
          <div>
            {deletedTask.map((item) => {
              return (
                <div
                  className={styles.taskItem}
                  key={item.gmsGoodsStrategyId}
                >
                  <div className={styles.taskName}>
                    {item.name}
                  </div>
                  <div>
                    {moment(item.lastUpdateTime).format('YYYY.MM.DD')}
                    删除
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}
