import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import type { ReactNode } from 'react';
import React, { Component } from 'react';

import styles from './index.less';

interface IProps<T> {
  data: T;
}

interface Data {
  name: string;
  value: string | number;
  trend?: number | string;
  growthRat?: string | string;
}

export default class DataAnalysis extends Component<IProps<Data>> {
  render(): ReactNode {
    const { data: { name, value, trend, growthRat }} = this.props;
    return (
      <div className={styles.dataAnalysisCard}>
        <div className={styles.cardName}>
          {name}
        </div>
        <div className={styles.cardValue}>
          {value}
        </div>
        {
          growthRat && growthRat !== null
            ? (
              <div className={styles.cardAnalysis}>
                环比
                <span className={trend == 0 ? styles.upStyle : styles.downStyle}>
                  {
                    trend == 0 ? <ArrowUpOutlined/> : <ArrowDownOutlined/>
                  }
                  {growthRat}
                </span>
              </div>
            )
            : ''
        }
      </div>
    );
  }
}
