import { Modal, Radio, Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { ProductInfo } from '../component';
import styles from './index.less';
import ProductData from './productData';
import type Store from './store';
import Strategy from './strategy';

const ProductDataModal: React.FC<{ store; }> = observer((props) => {
  const {
    visible,
    closeModal,
    goodsInfo,
    activeTab,
    loading,
  } = props.store;
  const {
    goodsName,
    mainPicUrl,
  } = goodsInfo;
  return (
    <Modal
      bodyStyle={{
        marginTop: '48px',
        padding: '24px',
        borderTop: '1px solid rgba(0, 0, 0, 0.06)',
        overflow: 'auto',
      }}
      footer={null}
      maskClosable={false}
      onCancel={closeModal}
      open={visible}
      style={{ height: '80%' }}
      width={1200}
      wrapClassName={styles.modalWrapper}
    >
      <ProductInfo
        info={goodsName}
        url={mainPicUrl}
      />
      <TitleTabs store={props.store}/>
      <Spin spinning={loading}>
        {
          activeTab === 1 ? <ProductData store={props.store}/> : <Strategy store={props.store}/>
        }
      </Spin>
    </Modal>
  );
});

const TitleTabs: React.FC<{ store: Store; }> = observer((props) => {
  const {
    activeTab,
    handleTabChange,
  } = props.store;
  return (
    <Radio.Group
      buttonStyle="solid"
      className={styles.tabs}
      onChange={(e) => handleTabChange(e.target.value)}
      value={activeTab}
    >
      <Radio.Button
        value={1}
      >
        商品数据
      </Radio.Button>
      <Radio.Button
        value={2}
      >
        策略任务
      </Radio.Button>
    </Radio.Group>
  );
});
export default ProductDataModal;
