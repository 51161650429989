import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import styles from './index.less';

interface IProps {
  value?: number;
  onChange?: (v: number) => void;
  platformList: Array<{
    platformType: number;
    platformTypeName: string;
    icon: string;
  }>;
}

const PlatformSelector = (props: IProps) => {
  const { value, onChange, platformList } = props;

  const [
    selectedPlatform,
    setSelectedPlatform,
  ] = useState<number>();

  const onClickPlatform = (v: number) => {
    setSelectedPlatform(v);
    onChange?.(v);
  };

  useEffect(() => {
    setSelectedPlatform(value);
  }, [value]);

  return (
    <div className={styles.container}>
      {
        platformList.map((platform) => {
          return (
            <div
              className={styles.platform}
              key={platform.platformType}
              onClick={() => onClickPlatform(platform.platformType)}
            >
              <div className={cx(styles.iconWrapper, { [styles.selected]: platform.platformType === selectedPlatform })}>
                <img
                  className={styles.icon}
                  src={platform.icon}
                />
              </div>
              <div>
                {platform.platformTypeName}
              </div>
            </div>
          );
        })
      }
    </div>
  );
};

export default PlatformSelector;
