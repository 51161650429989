import { Spin, Tabs } from 'antd';
import ReactECharts from 'echarts-for-react';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { TitleBar } from '../component';
import Charts from './charts/index';
import DataCard from './dataCard/index';
import styles from './index.less';
import type Store from './store';

const saleTendTab = [
  {
    label: '最近7天',
    value: 7,
  },
  {
    label: '最近30天',
    value: 30,
  },
  {
    label: '最近90天',
    value: 90,
  },
  {
    label: '上新首月',
    value: 1,
  },
];

const style = {
  with: '1200px',
  height: '197px',
  marginBottom: '39px',
};

const ProductData: React.FC<{ store: Store; }> = observer((props) => {
  const {
    businessDetailInfo,
    salesTabChange,
    saleTrendTabKey,
    lineOption,
    timeRange,
    onSkuDataTimeChange,
    skuTableData,
    saleSkuAnalysisOption,
    colorAnalysisOption,
    colorTableData,
    sizeTableData,
    sizeAnalysisOption,
    handleSkuTabChange,
    skuTabKey,
    skuLoading,
    salesTrendLoading,
  } = props.store;
  const tabItems = saleTendTab && saleTendTab.map((item) => {
    return {
      key: item.value.toString(),
      label: item.label,
      children: (
        <Spin spinning={salesTrendLoading}>
          <ReactECharts
            lazyUpdate
            notMerge
            option={lineOption}
            style={style}
          />
        </Spin>),
    };
  });
  const skuTabItem = [
    {
      key: '0',
      label: 'sku分析',
      children: (
        <Charts
          onSkuDataTimeChange={onSkuDataTimeChange}
          option={saleSkuAnalysisOption}
          skuLoading={skuLoading}
          tableData={skuTableData}
          timeRange={timeRange}
          type="SKU"
        />),
    },
    {
      key: '1',
      label: '颜色分析',
      children: (
        <Charts
          onSkuDataTimeChange={onSkuDataTimeChange}
          option={colorAnalysisOption}
          skuLoading={skuLoading}
          tableData={colorTableData}
          timeRange={timeRange}
          type="颜色"
        />),
    },
    {
      key: '2',
      label: '尺码分析',
      children: (
        <Charts
          onSkuDataTimeChange={onSkuDataTimeChange}
          option={sizeAnalysisOption}
          skuLoading={skuLoading}
          tableData={sizeTableData}
          timeRange={timeRange}
          type="尺寸"
        />),
    },
  ];
  return (
    <div
      className={styles.dataAnalysisContainer}
    >
      <TitleBar text="销售数据"/>
      <div className={styles.saleData}>
        {
          businessDetailInfo && businessDetailInfo.map((dataItem) => {
            return (
              <DataCard
                data={dataItem}
                key={dataItem.name}
              />
            );
          })
        }
      </div>
      <TitleBar text="销售趋势"/>
      <div className={styles.saleTrend}>
        <Tabs
          activeKey={saleTrendTabKey}
          items={tabItems}
          onTabClick={salesTabChange}
        />
      </div>
      <TitleBar text="SKU数据"/>
      <div className={styles.skuData}>
        <Tabs
          activeKey={skuTabKey}
          items={skuTabItem}
          onChange={handleSkuTabChange}
        />
      </div>
    </div>
  );
});

export default ProductData;
