import React from 'react';
import styles from './index.less';

interface ShopIconProps{
  text: string;
  color?: string;
  num?: number;
}
export const ShopIcon: React.FC<ShopIconProps> = (props) => {
  const { text, color, num } = props;
  return (
    <div
      className={styles.icon}
      style={{ backgroundColor: color }}
    >
      {text}
      { num && (
        <>
          <div className={styles.iconNum}/>
          <div className={styles.iconNumText}>
            {num}
          </div>
        </>
      )}
    </div>
  );
};

/**
   * 标题
   * @param text 标题名称
  */
export const TitleBar: React.FC<{ text: string; }> = (props) => {
  const { text } = props;
  return (
    <div className={styles.titleBar}>
      {
        text
      }
    </div>
  );
};

/**
   * 表格内商品信息
   * @param url 商品图片
   * @param url 商品信息
  */
export const ProductInfo: React.FC<{ url: string; info: string; }> = ((props) => {
  const { url, info } = props;
  return (
    <div className={styles.productInfoWrapper}>
      <img
        className={styles.productImg}
        src={url}
      />
      <div>
        {info}
      </div>
    </div>
  );
});

