import { Button, Card, Alert, Space } from 'antd';
import { EgGrid, ExportModal, NormalProgrammeComponent } from 'egenie-utils';
import { observer } from 'mobx-react';
import React from 'react';
import { LaunchModal, TopTab } from '../components';
import DownloadModal from '../goodsDetail/modal/download';
import { RightDrawer } from './drawer';
import { WarehouseShelveModal } from './drawer/warehouseShelveModal';
import styles from './index.less';
import ProductDataModal from './productDataModal';
import Store from './store';
import Strategy from './strategy';
import BatchLunchModal from './batchLunchModal';

const store = new Store();

const MyStyle = observer(() => {
  const { programme, egGridModel, drawerStore, downloadStore, launchStore, exportStore, statusList, activeStatus, handleTabChange, batchLunchStore } = store;
  const { strategyStore, productAnalysisStore } = drawerStore;
  return (
    <div className={styles.page}>
      <TopTab
        activeKey={activeStatus}
        hideAll
        list={statusList}
        onTabChange={handleTabChange}
      />
      <Card className={styles.programmeCard}>
        <NormalProgrammeComponent store={programme}/>
      </Card>
      <ButtonPart store={store}/>
      <div className={styles.gridWrapper}>
        <EgGrid store={egGridModel}/>
      </div>
      <RightDrawer store={drawerStore}/>
      <Strategy store={strategyStore}/>
      <ProductDataModal store={productAnalysisStore}/>
      <DownloadModal store={downloadStore}/>
      <LaunchModal store={launchStore}/>
      <ExportModal store={exportStore}/>
      <WarehouseShelveModal store={drawerStore}/>
      <BatchLunchModal store={batchLunchStore}/>
    </div>
  );
});

const ButtonPart: React.FC<{ store: Store; }> = observer((props) => {
  const { exportStyle, checkRemove, onClickBatchShopButton, checkSameCategoryLoading } = props.store;
  return (
    <div className={styles.buttonWrapper}>
      <Space>
        <Button
          loading={checkSameCategoryLoading}
          onClick={onClickBatchShopButton}
          type="primary"
        >
          批量铺货
        </Button>
        <Button
          onClick={exportStyle}
        >
          <i className={`icon-export ${styles.buttonIcon}`}/>
          批量导出
        </Button>
        <Button
          onClick={() => {
            checkRemove();
          }}
        >
          批量移出
        </Button>
        <Alert
          message="批量铺货仅支持相同类目，例如选择6款连衣裙，一起铺货，提高效率"
          showIcon
        />
      </Space>
    </div>
  );
});

export default MyStyle;
