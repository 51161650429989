export const LINE_BASE_OPTIONS = {
  tooltip: {
    trigger: 'axis',
    formatter: (params) => {
      if (params.length > 0) {
        const M = params[0].name.split('-')[0];
        const D = params[0].name.split('-')[1];
        return `
        <div>
          <p>${M}月${D}日</p>
          <p><span>销售量<span><span style="color: #4D94FF; margin-left: 4px">${params[0].value}件</span></p>
        </div>
        `;
      }
      return null;
    },
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    axisLine: {
      show: false,
      lineStyle: { color: '#999BA4' },
    },
    axisTick: { show: false },
  },
  yAxis: {
    type: 'value',
    axisLine: {
      show: false,
      lineStyle: { color: '#999BA4' },
    },
  },
  grid: {
    x: 40,
    y: 30,
    x2: 20,
    y2: 23,
  },
  series: [
    {
      data: [],
      type: 'line',
      smooth: true,
      symbol: 'none',
    },
  ],
};

export const PIE_BASE_OPTIONS = {
  tooltip: { trigger: 'item' },
  legend: {
    orient: 'vertical',
    x: 300,
    y: 'center',
    itemHeight: 3,
    textStyle: {
      rich: {
        a: {
          color: '#6D6E78',
          fontSize: '14px',
          width: 114,
        },
        b: {
          width: 38,
          color: '#C5C3CB',
          fontSize: '14px',
        },
        c: {
          width: 70,
          padding: [
            0,
            40,
            0,
            0,
          ],
          color: '#6D6E78',
          fontSize: '14px',
        },
        d: {
          color: '#6D6E78',
          fontSize: '14px',
        },
      },
    },
  },
  series: [
    {
      type: 'pie',
      radius: [
        '50%',
        '68.75%',
      ],
      center: [
        130,
        '50%',
      ],
      avoidLabelOverlap: false,
      label: {
        show: false,
        position: 'center',
      },
      emphasis: {
        label: {
          show: true,
          fontSize: '24',
          fontWeight: 'bold',
        },
      },
      labelLine: { show: false },
      data: [],
    },
  ],
};
