import { message, Modal } from 'antd';
import type { BaseData, PureData } from 'egenie-common';
import { EgGridModel, ExportStore, NormalProgramme, request } from 'egenie-utils';
import { omit } from 'lodash';
import { action, observable } from 'mobx';
import { api } from '../../utils';
import { LaunchStore } from '../components';
import DownLoadStore from '../goodsDetail/modal/download/store';
import { COLUMNS, commonDict, FILTER_ITEMS, statusDict, categoryRequest } from './constant';
import DrawerStore from './drawer/store';
import type { ICheckRemove, ICommonDict, IStyleData, ICategoryOption } from './interface';
import BatchLunchStore from './batchLunchModal/store';

export default class Store {
  constructor() {
    this.initOptions();

    // 判断是否从已加入我的款式跳转而来
    if (window.top.location.search.includes('myStyle')) {
      this.activeStatus = 'waitNum';
    }

    this.programme.handleSearch();

    const { origin, pathname } = window.top.location;

    window.top.history.replaceState({}, '', `${origin}${pathname}`);
  }

  @observable public statusList = [];

  @observable public activeStatus = 'allNum';

  @observable public drawerStore = new DrawerStore(this);

  @observable public exportStore = new ExportStore({ parent: this });

  @observable public downloadStore = new DownLoadStore({ parent: this });

  @observable public launchStore = new LaunchStore({ parent: this });

  public batchLunchStore = new BatchLunchStore();

  @observable public programme = new NormalProgramme({
    count: 6,
    filterItems: FILTER_ITEMS(this),
    handleSearch: () => {
      this.getStatusList();
      return this.egGridModel.onQuery();
    },
  });

  @observable public checkSameCategoryLoading = false;

  // 缓存类目列表
  @observable public categoryList: ICategoryOption[] = [];

  public queryStyleList = (params) => {
    // 添加salesStatus
    Object.assign(params, { salesStatus: statusDict[this.activeStatus].value });
    const queryParams = this.programme.filterItems.params;

    // 处理开始时间和结束时间
    if (queryParams.createTime) {
      Object.assign(queryParams, {
        startCreateTime: (queryParams.createTime as string).split(',')[0],
        endCreateTime: (queryParams.createTime as string).split(',')[1],
      });

      delete queryParams.createTime;
    }

    // 处理商品上架 开始时间和结束时间
    if (queryParams.putAwayTime) {
      Object.assign(queryParams, {
        startPutAwayTime: (queryParams.putAwayTime as string).split(',')[0],
        endPutAwayTime: (queryParams.putAwayTime as string).split(',')[1],
      });

      delete queryParams.putAwayTime;
    }

    // 处理类目
    if (queryParams.styleCategoryId && typeof queryParams.styleCategoryId === 'string') {
      const arr = queryParams.styleCategoryId.split(',');

      // @ts-ignore
      queryParams.styleCategoryId = arr[arr.length - 1];
    }

    this.egGridModel.loading = true;
    return request<BaseData<IStyleData<PureData>>>({
      url: api.styleListQuery,
      method: 'POST',
      data: {
        ...queryParams,
        ...omit(params, ['filterParams']),
      },
    }).then((res) => {
      const { pageList } = res.data;
      this.egGridModel.rows = pageList.list;
      if (!pageList.list?.length) {
        this.egGridModel.showNormalEmpty = true;
      }
      this.egGridModel.total = pageList.totalCount;
    })
      .finally(() => {
        this.egGridModel.loading = false;
      });
  };

  public handlePageChange = (page: number, pageSize: number): void => {
    this.queryStyleList({
      page,
      pageSize,
    });
  };

  // 处理顶部tab变化
  @action public handleTabChange = (activeStatus: string) => {
    this.activeStatus = activeStatus;

    // 重置分页数据
    this.egGridModel.current = 1;

    this.programme.handleSearch();
  };

  @observable public egGridModel: EgGridModel = new EgGridModel({
    columns: COLUMNS(this).map((item) => ({
      ...item,
      resizable: true,
    })),
    rows: [],
    primaryKeyField: 'gmsStyleId',
    showRefresh: true,
    showCheckBox: true,
    showSelectedTotal: true,
    rowHeight: 127,
    pageSizeOptions: [
      '50',
      '100',
      '200',
    ],
    api: {
      onQuery: this.queryStyleList,
      onRefresh: this.programme.handleSearch,
      onPageChange: this.handlePageChange,
      onShowSizeChange: this.handlePageChange,
    },
  });

  // 打开下载弹窗
  @action public openDownload = (id): void => {
    this.downloadStore.onModalShow(id, 0);
  };

  // 打开一键铺货弹窗
  @action public openLaunch = (id) => {
    this.launchStore.openModal(id, 0);
  };

  // 导出
  public exportStyle = () => {
    const selectIds = Array.from(this.egGridModel.selectedIds); // 被选中的id数组
    const queryParam = this.programme.filterItems.params; // 获取查询条件

    const queryParamShow = this.programme.filterItems.translateParams; // 获取查询条件中文值

    // 增加上架状态
    if (this.activeStatus !== 'allNum') {
      Object.assign(queryParam, { salesStatus: statusDict[this.activeStatus].value });
      queryParamShow.push(`上架状态:${statusDict[this.activeStatus].label}`);
    }
    if (selectIds.length === 0) {
      Modal.confirm({
        title: '提示',
        content: '未选择数据将导出全部数据?',
        onOk: () => {
          this.exportStore.onShow('我的款式导出', 'gms_style', '', queryParam, queryParamShow.join(' ')); // ids不传即代表导出全部数据
        },
      });
      return;
    }
    this.exportStore.onShow('我的款式导出', 'gms_style', selectIds.join(','), queryParam, queryParamShow.join(' ')); // 勾选部分数据
  };

  // 初始化数据
  @action public initOptions = async() => {
    // 查字典
    const res = await request<BaseData<ICommonDict>>({
      url: api.getDict,
      method: 'POST',
      data: commonDict,
    });

    const { vendorShopList, chooserList, shopList } = res.data;
    const publishShopNameList = this.mapOptions(shopList, 'value', 'value');
    const chooserIdList = this.mapOptions(chooserList, 'key', 'value');
    const vendorShopIdList = this.mapOptions(vendorShopList, 'key', 'value');

    // const statusList = this.mapOptions(salesStatusList, 'key', 'value');

    // 查询一级类目列表，id传0
    const rootCaterogyList = await categoryRequest('0');
    this.categoryList = rootCaterogyList;

    this.programme.filterItems.addDict({
      publishShopName: publishShopNameList,
      chooserId: chooserIdList,
      vendorShopId: vendorShopIdList,
      styleCategoryId: rootCaterogyList,
    });
  };

  // 获取上架状态
  private getStatusList = async() => {
    // 处理上架状态
    const countRes = await request<BaseData<Record<string, string>>>({
      url: '/api/gms/pc/style/count',
      method: 'POST',
    });
    this.statusList = Object.keys(countRes.data).map((item) => ({
      label: statusDict[item].label,
      value: item,
      number: countRes.data[item],
    }));
  };

  // 移出
  public checkRemove = (gmsStyleId?: number) => {
    // 判断是否为批量移出
    if (!gmsStyleId && this.egGridModel.selectedIds.size === 0) {
      message.warning('请选择数据');
      return;
    }
    Modal.confirm({
      title: '提示',
      content: '确认要移出吗？',
      onOk: async() => {
        const data = gmsStyleId ? { gmsStyleIds: [gmsStyleId]} : { gmsStyleIds: Array.from(this.egGridModel.selectedIds) };

        // 先检查是否可以移出
        const checkRes = await request<BaseData<ICheckRemove>>({
          url: '/api/gms/pc/style/checkRemove',
          method: 'POST',
          data,
        });

        const { hasPuting, hasManualMatch, manualMatchedStyleNos } = checkRes.data;

        if (hasPuting) {
          Modal.confirm({
            title: '提示',
            content: '有店铺正在上架，确认要移出？',
            onOk: () => {
              return this.removeStyle(data, hasManualMatch, manualMatchedStyleNos);
            },
          });
        } else {
          this.removeStyle(data, hasManualMatch, manualMatchedStyleNos);
        }
      },
    });
  };

  // 请求接口移出款式
  private removeStyle = (data, hasManualMatch: boolean, manualMatchedStyleNos?: string[]) => {
    return new Promise((resolve, reject) => {
      if (hasManualMatch) {
        Modal.confirm({
          title: '提示',
          okText: '前往查看',
          content: '选择的款式已被关联到店铺商品，取消关联后可移出，是否前往查看关联商品？',
          onOk: () => {
            window.top.egenie.openTab(`/egenie-ts-vogue/storeGoodsManagement/index?styleNo=${manualMatchedStyleNos?.join(',')}`, 751, '店铺商品管理');
            resolve(0);
          },
          onCancel: () => {
            reject();
          },
        });
      } else {
        request<BaseData>({
          url: '/api/gms/pc/style/remove',
          method: 'POST',
          data,
        }).then((res) => {
          message.success(res.data || '移出成功');
          this.programme.handleSearch();
          resolve(0);
        });
      }
    });
  };

  public mapOptions = (data, key, val) => {
    return data.map((item) => ({
      label: item[val],
      value: item[key],
    }));
  };

  /**
   * 点击批量铺货按钮
   */
  public onClickBatchShopButton = async() => {
    const selectedRows = this.egGridModel.selectRows;
    if (selectedRows.length === 0) {
      message.warning('请至少选择一个商品');
      return;
    }
    try {
      this.checkSameCategoryLoading = true;
      await request({
        url: '/api/gms/pc/style/batchStyle/checkCategorySame',
        method: 'POST',
        data: { gmsStyleIds: selectedRows.map((i) => i.gmsStyleId) },
      });
      const ids = selectedRows.map((i) => ({
        gmsStyleId: i.gmsStyleId,
        posGoodsId: i.posGoodsId,
      }));
      this.batchLunchStore.show(ids);
    } finally {
      this.checkSameCategoryLoading = false;
    }
  };

  /**
   * 动态加载类目列表（二三级）
   */
  @action
  public loadCategoryList = async(selectedOptions: ICategoryOption[]) => {
    const targetOption = selectedOptions[selectedOptions.length - 1];
    targetOption.loading = true;

    const data = await categoryRequest(targetOption.value);
    targetOption.loading = false;
    targetOption.children = data;
    this.categoryOptionIterator(this.categoryList, targetOption, data);
    this.programme.filterItems.updateFilterItem([
      {
        field: 'styleCategoryId',
        type: 'cascader',
        data: this.categoryList,
      },
    ]);
  };

  /**
   * 更新类目列表
   */
  @action
  private categoryOptionIterator = (list: ICategoryOption[], target: ICategoryOption, children: ICategoryOption[]) => {
    for (let i = 0; i < list.length; i++) {
      const item = list[i];
      if (item.children && item.children.length > 0) {
        this.categoryOptionIterator(item.children, target, children);
      } else if (item.value === target.value) {
        item.loading = false;
        item.children = children;
        break;
      }
    }
  };
}

