import React from 'react';
import { Modal, Button, Checkbox, Space, Form, Row, Col } from 'antd';
import { PlusOutlined, SyncOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import type Store from './store';
import styles from './index.less';
import PlatformSelector from './platformSelector';

export default observer((props: { store: Store; }) => {
  const { visible, close, formRef, onOk, onClickAddShopButton, onPlatformChange, platformList, shopList, onClickRefreshIcon, refreshLoading, loading, ids } = props.store;
  return (
    <Modal
      centered
      footer={(
        <div className={styles.footer}>
          <div>
            已选：
            {ids.length}
            款
          </div>
          <Space>
            <Button onClick={close}>
              取消
            </Button>
            <Button
              loading={loading}
              onClick={onOk}
              type="primary"
            >
              确定
            </Button>
          </Space>
        </div>
      )}
      forceRender
      maskClosable={false}
      onCancel={close}
      open={visible}
      title="一键铺货"
    >
      <Form
        labelCol={{ span: 4 }}
        ref={formRef}
        wrapperCol={{ span: 20 }}
      >
        <Form.Item
          label="铺货平台"
          name="platform"
        >
          <PlatformSelector
            onChange={onPlatformChange}
            platformList={platformList}
          />
        </Form.Item>
        <Form.Item
          label="铺货店铺"
        >
          <div className={styles.shopOperation}>
            <Button
              ghost
              icon={<PlusOutlined/>}
              onClick={onClickAddShopButton}
              type="primary"
            >
              添加店铺
            </Button>
            <SyncOutlined
              className={styles.refreshIcon}
              onClick={onClickRefreshIcon}
              spin={refreshLoading}
            />
          </div>
          <Form.Item
            name="shopIds"
            noStyle
          >
            <Checkbox.Group style={{ width: '100%' }}>
              <Row>
                {shopList.map((shop) => {
                  return (
                    <Col
                      key={shop.shopId}
                      span={12}
                      style={{ marginBottom: 16 }}
                    >
                      <Checkbox
                        disabled={shop.authStatus === 2}
                        value={shop.shopId}
                      >
                        <span>
                          {shop.shopName}
                        </span>
                        {shop.authStatus === 2 && (
                          <span className={styles.expiredTag}>
                            已过期
                          </span>
                        )}
                      </Checkbox>
                    </Col>
                  );
                })}
              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Form.Item>
      </Form>
    </Modal>
  );
});
