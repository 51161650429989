import { Radio, Table, Spin } from 'antd';
import type { RadioChangeEvent } from 'antd';
import ReactECharts from 'echarts-for-react';
import { observer } from 'mobx-react';
import type { ReactNode } from 'react';
import React, { Component } from 'react';
import styles from './index.less';

interface IProps {
  option?: any;
  tableData: any[];
  skuLoading: boolean;
  type: string;
  timeRange: string;
  onSkuDataTimeChange: (val: RadioChangeEvent) => void;
}

@observer
export default class DataAnalysis extends Component<IProps> {
  render(): ReactNode {
    const { option, type, tableData, timeRange, onSkuDataTimeChange, skuLoading } = this.props;
    const style = {
      height: '320px',
      with: '550',
    };
    const column = [
      {
        title: '排名',
        dataIndex: 'rank',
        key: 'rank',
      },
      {
        title: type,
        dataIndex: 'salesName',
        key: 'rank',
      },
      {
        title: '销量',
        dataIndex: 'salesNum',
        key: 'rank',
      },
      {
        title: '销量占比',
        dataIndex: 'salesPercent',
        key: 'rank',
      },
      {
        title: '缺货率',
        dataIndex: 'lackRate',
        key: 'rank',
      },
    ];
    return (
      <div className={styles.chartsContainer}>
        <Radio.Group
          buttonStyle="solid"
          defaultValue="7"
          onChange={(e) => onSkuDataTimeChange(e)}
          value={timeRange}
        >
          <Radio.Button value="7">
            最近7天
          </Radio.Button>
          <Radio.Button value="30">
            最近30天
          </Radio.Button>
          <Radio.Button value="90">
            最近三月
          </Radio.Button>
          <Radio.Button value="new30">
            上新首月
          </Radio.Button>
        </Radio.Group>
        <Spin spinning={skuLoading}>
          <ReactECharts
            lazyUpdate
            notMerge
            option={option}
            style={style}
          />
          <Table
            columns={column}
            dataSource={tableData}
            pagination={{ hideOnSinglePage: true }}
            rowKey="salesName"
            size="middle"
            style={{ marginTop: 22 }}
          />
        </Spin>
      </div>
    );
  }
}
