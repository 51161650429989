import type { BaseData } from 'egenie-common';
import { request } from 'egenie-utils';
import { observable, action } from 'mobx';
import { api } from '../../../utils';
import { LINE_BASE_OPTIONS, PIE_BASE_OPTIONS } from './chartsOptions';
import type { GoodsSaleTrend, SaleTrendCondition, SaleSkuData, SkuDataCondition, SalesData, StrategicTask } from './interface';

export default class Store {
  constructor(parent) {
    this.parent = parent;
  }

  public parent;

  @observable public visible = false;

  @observable public loading = false;

  @observable public timeRange = '7'; // sku数据筛选的时间范围

  @observable public activeTab = 1;// 默认打开商品数据tab

  @observable public gmsGoodsId = 0;

  @observable public goodsInfo = {};// 商品信息

  @observable public businessDetailInfo = [];// 销售数据

  @observable public skuLoading = false;// sku数据切换时间loading

  @observable public salesTrendLoading = false;// 销售趋势时间选择loading

  @observable public getSaleTrendCondition: SaleTrendCondition = {
    gmsGoodsId: 0,
    arrivalDays: 7,
    arrivalType: 2,
  };// 销售趋势查询条件

  @observable public getSkuDataCondition: SkuDataCondition = {
    gmsGoodsId: 0,
    arrivalDays: 7,
    arrivalType: 2,
  };// sku数据查询条件

  @observable public saleSkuAnalysisOption = {}; // sku分析数据

  @observable public colorAnalysisOption = {}; // 颜色分析数据

  @observable public sizeAnalysisOption = {}; // 尺码分析数据

  @observable public skuTableData = []; // sku表格数据

  @observable public colorTableData = []; // 颜色表格数据

  @observable public sizeTableData = []; // 尺码表格数据

  @observable public saleTrend = {}; // 销售趋势数据

  @observable public unfinishedTask = []; // 未完成策略

  @observable public completedTask = []; // 已完成策略

  @observable public deletedTask = []; // 已删除策略

  @observable public skuTabKey = '0';// sku选中tab

  @observable public saleTrendTabKey = '7';// 销售趋势tab

  // 销售趋势tab页改变时获取数据
  @action public salesTabChange = (key: string) => {
    this.saleTrendTabKey = key;
    if (key === '1') {
      this.getSaleTrendCondition.arrivalType = 1;
      this.getSaleTrendCondition.arrivalDays = 30;
    } else {
      this.getSaleTrendCondition.arrivalType = 2;
      this.getSaleTrendCondition.arrivalDays = Number(key);
    }
    this.salesTrendLoading = true;
    this.getGoodsSaleTrend().finally(() => {
      this.salesTrendLoading = false;
    });
  };

  @observable public handleTabChange = (tab) => {
    this.activeTab = tab;
    if (this.activeTab === 1) {
      this.getProductData();
    } else {
      this.getStrategicTask();
    }
  };// 商品数据或策略任务

  @observable public salesData = [];// 销售数据

  @observable public lineOption = {};// 销售趋势数据

  @action public openModal = (row, tab: number) => {
    this.visible = true;
    this.activeTab = tab;
    this.goodsInfo = row;
    const { gmsGoodsId } = row;
    this.gmsGoodsId = gmsGoodsId;
    if (this.activeTab === 1) {
      this.getProductData();
    } else {
      this.getStrategicTask();
    }
  };

  @action public getProductData = async() => {
    this.loading = true;
    try {
      await this.getSalesData();// 销售数据
      await this.getGoodsSaleTrend();// 销售趋势
      await this.getSkuData();// sku数据
    } catch (e) {
      console.log(e);
    } finally {
      this.loading = false;
    }
  };

  // sku 数据tab变化
  @action public handleSkuTabChange = (val) => {
    this.skuTabKey = val;
    this.timeRange = '7';
  };
  
  @action public closeModal = () => {
    this.visible = false;
    this.loading = false;
    this.skuLoading = false;
    this.salesTrendLoading = false;
    this.skuTabKey = '0';
    this.saleTrendTabKey = '7';
    this.timeRange = '7';
    this.businessDetailInfo = [];
    this.saleTrend = {};
    this.lineOption = {};
    this.saleSkuAnalysisOption = {};
    this.skuTableData = [];
    this.completedTask = [];
    this.unfinishedTask = [];
    this.deletedTask = [];
  };

  @action public getSalesData = () => {
    return request<SalesData>({
      url: '/api/gms/goods/goodsBusinessStat',
      method: 'POST',
      data: { gmsGoodsId: this.gmsGoodsId },
    }).then((res) => {
      this.businessDetailInfo = res.businessDetailInfoVos;
    });
  };

  // 获取销售趋势
  @action public getGoodsSaleTrend = async(): Promise<void> => {
    this.getSaleTrendCondition.gmsGoodsId = this.gmsGoodsId;
    const res = await request<BaseData<GoodsSaleTrend>>({
      url: api.getGoodsSaleTrend,
      method: 'post',
      data: this.getSaleTrendCondition,
    });
  
    // this.saleTrend = res.data;
    this.lineOption = {
      ...LINE_BASE_OPTIONS,
      xAxis: {
        ...LINE_BASE_OPTIONS.xAxis,
        data: res.data.timeline.map((item) => {
          return item.slice(5);
        }),
      },
      series: [
        {
          ...LINE_BASE_OPTIONS.series[0],
          data: res.data.sales,
        },
      ],
    };
  };
    
  // 获取sku数据
  @action public getSkuData = async(): Promise<void> => {
    this.getSkuDataCondition.gmsGoodsId = this.gmsGoodsId;
    const res = await request<BaseData<SaleSkuData>>({
      url: api.getSkuData,
      method: 'post',
      data: this.getSkuDataCondition,
    });

    this.getSkuAnalysisData(res.data);
    this.getSkuTableData(res.data);
  };

  // 处理表格数据
  @action public getSkuTableData = (data: SaleSkuData): void => {
    const { allSalesSkuCountVos, allSalesSkuColorCountVos, allSalesSkuSizeCountVos } = data;
    this.skuTableData = this.handleTableData(allSalesSkuCountVos);
    this.colorTableData = this.handleTableData(allSalesSkuColorCountVos);
    this.sizeTableData = this.handleTableData(allSalesSkuSizeCountVos);
  };
  
  public handleTableData = (data) => {
    const arr = data.reduce((pre, cur, index) => {
      pre = [
        ...pre,
        {
          ...cur,
          rank: index + 1,
        },
      ];
      return pre;
    }, []);
    return arr;
  };

  // 处理sku分析、颜色分析、尺码分析数据
  @action public getSkuAnalysisData = (data: SaleSkuData): void => {
    const { allSalesSkuCountVos, allSalesSkuColorCountVos, allSalesSkuSizeCountVos } = data;
    const saleSkuAnalysisData = allSalesSkuCountVos.reduce((pre, cur) => {
      pre = [
        ...pre,
        {
          name: cur.salesName,
          value: cur.salesNum,
          salesPercent: cur.salesPercent,
        },
      ];
      return pre;
    }, []);
    const colorAnalysisData = allSalesSkuColorCountVos.reduce((pre, cur) => {
      pre = [
        ...pre,
        {
          name: cur.salesName,
          value: cur.salesNum,
          salesPercent: cur.salesPercent,
        },
      ];
      return pre;
    }, []);
    const sizeAnalysisData = allSalesSkuSizeCountVos.reduce((pre, cur) => {
      pre = [
        ...pre,
        {
          name: cur.salesName,
          value: cur.salesNum,
          salesPercent: cur.salesPercent,
        },
      ];
      return pre;
    }, []);
    this.saleSkuAnalysisOption = {
      ...PIE_BASE_OPTIONS,
      legend: {
        ...PIE_BASE_OPTIONS.legend,
        formatter: (name: any) => {
          const dataItem = saleSkuAnalysisData.filter((item) => {
            return item.name === name;
          });
          return [
            `{a|${dataItem[0].name}}`,
            '{b||}',
            `{c|${dataItem[0].salesPercent}}`,
            `{d|${dataItem[0].value}件}`,
          ].join('');
        },
      },
      series: [
        {
          type: 'pie',
          radius: [
            '50%',
            '68.75%',
          ],
          center: [
            110,
            '50%',
          ],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: 'center',
          },
          emphasis: {
            label: {
              show: true,
              fontSize: '24',
              fontWeight: 'bold',
            },
          },
          labelLine: { show: false },
          data: [...saleSkuAnalysisData],
        },
      ],
    };
    this.colorAnalysisOption = {
      ...PIE_BASE_OPTIONS,
      legend: {
        ...PIE_BASE_OPTIONS.legend,
        formatter: (name: any) => {
          const dataItem = colorAnalysisData.filter((item) => {
            return item.name === name;
          });
          return [
            `{a|${dataItem[0].name}}`,
            '{b||}',
            `{c|${dataItem[0].salesPercent}}`,
            `{d|${dataItem[0].value}件}`,
          ].join('');
        },
      },
      series: [
        {
          type: 'pie',
          radius: [
            '50%',
            '68.75%',
          ],
          center: [
            110,
            '50%',
          ],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: 'center',
          },
          emphasis: {
            label: {
              show: true,
              fontSize: '24',
              fontWeight: 'bold',
            },
          },
          labelLine: { show: false },
          data: [...colorAnalysisData],
        },
      ],
    };
    this.sizeAnalysisOption = {
      ...PIE_BASE_OPTIONS,
      legend: {
        ...PIE_BASE_OPTIONS.legend,
        formatter: (name: any) => {
          const dataItem = sizeAnalysisData.filter((item) => {
            return item.name === name;
          });
          return [
            `{a|${dataItem[0].name}}`,
            '{b||}',
            `{c|${dataItem[0].salesPercent}}`,
            `{d|${dataItem[0].value}件}`,
          ].join('');
        },
      },
      series: [
        {
          type: 'pie',
          radius: [
            '50%',
            '68.75%',
          ],
          center: [
            110,
            '50%',
          ],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: 'center',
          },
          emphasis: {
            label: {
              show: true,
              fontSize: '24',
              fontWeight: 'bold',
            },
          },
          labelLine: { show: false },
          data: [...sizeAnalysisData],
        },
      ],
    };
  };

  // 由时间间隔获取sku数据
  @action public onSkuDataTimeChange = (e): void => {
    this.timeRange = e.target.value;
    if (this.timeRange === 'new30') {
      this.getSkuDataCondition.arrivalType = 1;
      this.getSkuDataCondition.arrivalDays = 30;
    } else {
      this.getSkuDataCondition.arrivalDays = Number(this.timeRange);
      this.getSkuDataCondition.arrivalType = 2;
    }
    this.skuLoading = true;
    this.getSkuData().finally(() => {
      this.skuLoading = false;
    });
  };

  // 获取商品的策略列表
  @action public getStrategicTask = async(): Promise<void> => {
    this.loading = true;
    try {
      const res = await request<BaseData<StrategicTask[]>>({
        url: api.getStrategicTask,
        method: 'post',
        data: { gmsGoodsId: this.gmsGoodsId },
      });
      console.log(res.data);
      const unfinished = [];
      const completed = [];
      const deleted = [];
      res.data.forEach((item) => {
        switch (item.status) {
          case 0:
            deleted.push(item);
            break;
          case 1:
            completed.push(item);
            break;
          case 2:
            unfinished.push(item);
            break;
        }
      });
      this.deletedTask = deleted;
      this.unfinishedTask = unfinished;
      this.completedTask = completed;
      console.log('deleted:', deleted, 'completed:', completed, 'unfinished:', unfinished);
    } catch (e) {
      console.log(e);
    } finally {
      this.loading = false;
    }
  };
}
