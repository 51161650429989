import { LeftOutlined } from '@ant-design/icons';
import { Drawer } from 'antd';
import { EgGrid } from 'egenie-utils';
import { observer } from 'mobx-react';
import React from 'react';
import styles from './index.less';
import type Store from './store';

export const RightDrawer: React.FC<{ store: Store; }> = observer((props) => {
  const {
    showDrawer,
    closeDrawer,
    egGridModel,
    styleName,
    styleNo,
  } = props.store;
  return (
    <Drawer
      bodyStyle={{
        display: 'flex',
        flexDirection: 'column',
        padding: '24px',
      }}
      closable={false}
      open={showDrawer}
      placement="right"
      width="66%"
    >
      <div className={styles.drawerTop}>
        <div
          className={styles.backIcon}
          onClick={closeDrawer}
        >
          <LeftOutlined/>
          {' '}
          返回
        </div>
        <div>
          {styleName}
        </div>
        <div>
          款式编码:
          {' '}
          {
            styleNo
          }
        </div>
      </div>
      <div className={styles.gridWrapper}>
        <EgGrid store={egGridModel}/>
      </div>
    </Drawer>
  );
});
