import React from 'react';
import { message, Modal } from 'antd';
import type { FormInstance } from 'antd';
import { observable, action, computed } from 'mobx';
import type { BaseData } from 'egenie-utils';
import { request } from 'egenie-utils';
import { nanoid } from 'nanoid';
import { BATCH_SHELVE_URL } from '../constant';
import type { Platform, ProductIds } from './interface';
import douyin from '../../../assets/images/douyin.png';
import kuaishou from '../../../assets/images/kuaishou.png';
import pdd from '../../../assets/images/pdd.png';
import taobao from '../../../assets/images/taobao.png';

const PLATFORM_ICON_MAP = {
  '1': taobao,
  '17': pdd,
  '21': douyin,
  '22': kuaishou,
};

export default class BatchLunchModalStore {
  constructor() {
    this.getUserType();
    this.getPlatformList();
  }

  public formRef = React.createRef<FormInstance<{
    platform: number;
    shopIds: number[];
  }>>();

  private smallClient = true;

  @observable public visible = false;

  @observable public loading = false;

  @observable public refreshLoading = false;

  @observable public platformList: Array<Platform & { icon: string; }> = [];

  @observable private selectedPlatform: number;

  // 商品id集合
  @observable public ids: ProductIds[] = [];

  @computed public get shopList() {
    return this.platformList.find((i) => i.platformType === this.selectedPlatform)?.pcShopVos ?? [];
  }

  // 默认选中的平台
  @computed private get defaultPlatform() {
    return this.platformList[0]?.platformType;
  }

  @action
  public show = (ids: ProductIds[]) => {
    this.visible = true;
    this.ids = ids;
  };

  @action
  public close = () => {
    this.visible = false;
    this.formRef.current.resetFields();
    this.selectedPlatform = this.defaultPlatform;
    this.formRef.current.setFieldValue('platform', this.defaultPlatform);
  };

  /**
   * 判断用户是否为小客户，跳转店铺授权页面不同
   */
  @action
  private getUserType = () => {
    request<BaseData<{
      tenantType: string;
    }>>({
      url: '/api/iac/dashboard/user',
      method: 'GET',
    }).then(({ data }) => {
      this.smallClient = data.tenantType?.includes('100001');
    });
  };

  /**
   * 获取平台列表（现阶段只做淘宝、快手、抖音、拼多多）
   */
  @action
  private getPlatformList = async() => {
    const { data } = await request<BaseData<Platform[]>>({
      url: '/api/gms/pc/common/batchDist/getClassifyShop',
      method: 'POST',
    });
    if (Array.isArray(data)) {
      this.platformList = data
        .map((i) => ({
          ...i,
          icon: PLATFORM_ICON_MAP[i.platformType],
        }));

      // 默认选择第一个平台
      const defaultPlatform = data[0]?.platformType;
      this.selectedPlatform = defaultPlatform;
      this.formRef.current.setFieldValue('platform', defaultPlatform);
    }
  };

  @action
  public onPlatformChange = (value: number) => {
    this.selectedPlatform = value;
    this.formRef.current.setFieldValue('shopIds', null);
  };

  public onClickAddShopButton = () => {
    if (this.smallClient) {
      window.top.egenie.openTab('/egenie-ts-vogue/shopAuthorization/index', '755', '店铺授权管理');
    } else {
      window.top.egenie.openTab('/egenie-ts-baseinfo/shopManage/index', '2695', '店铺管理');
    }
  };

  // 刷新店铺
  public onClickRefreshIcon = async() => {
    if (this.refreshLoading) {
      return;
    }
    try {
      this.refreshLoading = true;
      await this.getPlatformList();
    } finally {
      this.refreshLoading = false;
    }
  };

  // 检查店铺授权
  private checkShopAuth = (shopIds: number[]) => {
    const selectedShopList = this.shopList.filter((i) => shopIds.includes(i.shopId));
    let pass = true;
    for (let i = 0; i < selectedShopList.length; i++) {
      const shop = selectedShopList[i];
      if (shop.authStatus === 0) {
        Modal.confirm({
          title: `店铺 ${shop.shopName} 还未授权，请进行授权`,
          okText: '去授权',
          onOk: async() => {
            const res = await request<BaseData<string>>({ url: `/api/baseinfo/rest/shop/auth/${shop.shopId}` });
            window.open(res.data);
          },
        });
        pass = false;
        break;
      }
    }
    return pass;
  };

  public onOk = () => {
    const formValues = this.formRef.current.getFieldsValue();
    if (!(Array.isArray(formValues.shopIds) && formValues.shopIds.length > 0)) {
      message.warning('请至少选择一个店铺');
      return;
    }
    const pass = this.checkShopAuth(formValues.shopIds);
    if (!pass) {
      return;
    }
    const platform = formValues.platform;
    const ids = this.ids.map((i) => `${i.gmsStyleId},${i.posGoodsId}`).join(';');
    const shopIds = formValues.shopIds;
    const pageId = nanoid(5);
    const url = BATCH_SHELVE_URL[platform](ids, shopIds, pageId);
    window.top.egenie.openTab(url, pageId, '批量上架');
  };
}
