import { Button, Modal } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import type Store from './store';

// 仓库中上架
export const WarehouseShelveModal: React.FC<{ store: Store; }> = observer((props) => {
  const {
    showWarehouseModal,
    shopId,
    platformId,
    goodsId,
    shelveDirectly,
    shelveProduct,
    toggleWarehouseModal,
    shelveDirectlyLoading,
  } = props.store;
  return (
    <Modal
      destroyOnClose
      footer={
        [
          <Button

            // 抖音供应商版暂不支持编辑
            disabled={platformId === 32}
            key="1"
            onClick={() => {
              shelveProduct(shopId, platformId, goodsId);
            }}
          >
            重新编辑
          </Button>,
          <Button
            key="2"
            loading={shelveDirectlyLoading}
            onClick={() => {
              shelveDirectly(shopId, platformId, goodsId);
            }}
            type="primary"
          >
            直接上架
          </Button>,
        ]
      }
      maskClosable={false}
      onCancel={() => {
        toggleWarehouseModal(false);
      }}
      open={showWarehouseModal}
      title="提示"
    >
      该款式在仓库中，确定直接上架吗？
    </Modal>
  );
});

